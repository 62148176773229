import React, { useEffect } from 'react';
import { SupportCall, SupportText, SupportMessage, SupportTicket } from '@apps/common-ui';
import callSupportImage from '../../assets/CallSupport.svg';
import chatSupportImage from '../../assets/ChatSupport.svg';
import emailSupportImage from '../../assets/EmailSupport.svg';
import emailSupportCompletedImage from '../../assets/EmailSupportCompleted.svg';
import * as S from './index.styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../state/store';
import { RequestMethod, useApiRequest } from '../../hooks/useApiRequest';
import { types } from '@apps/common-utilities';
import toast from 'react-hot-toast';

const Support = () => {
    const [showMessageInput, setShowMessageInput] = React.useState(false);
    const { user } = useSelector((state: RootState) => state.session);
    const { callApi: createTicket } = useApiRequest(RequestMethod.POST);
    const [completed, setCompleted] = React.useState(false);

    const submitTicket = (ticket: string, issue?: string) => {
        const supportTicket: types.ISupportTicket = {
            message: ticket,
            fullName: user?.firstName && user?.lastName ? `${user.firstName} ${user.lastName}` : '',
            email: user?.email ? user.email : '',
            issue: issue || '',
        };
        createTicket('/support/ticket/care-team', supportTicket)
            .then(() => {
                toast.success('Ticket submitted successfully');
                setCompleted(true);
            }).catch(() => {
                toast.error('Error submitting ticket. Please try again later or use another method to contact support');
            });
    };

    return (
        <S.Container>
            {!showMessageInput ? (
                <>
                    <S.HeaderContainer>
                        <S.Header>Contact Support</S.Header>
                    </S.HeaderContainer>
                    <S.BodyContainer>
                        <SupportCall phoneNumber="18338075928" supportImage={callSupportImage} />
                        <SupportText phoneNumber="18338075928" supportImage={chatSupportImage} />
                        <SupportMessage
                          toMessage={() => setShowMessageInput(true)}
                          supportImage={emailSupportImage}
                          messageText="We will respond as soon as possible"
                        />
                    </S.BodyContainer>
                </>
            )
                : (
                    <SupportTicket
                      onBackClicked={() => setShowMessageInput(false)}
                      submitTicket={(ticket: string, issue?: string) => submitTicket(ticket, issue)}
                      completed={completed}
                      completedImage={emailSupportCompletedImage}
                      tenantUser={user}
                      issueRequired
                      issueList={[
                        { issue: 'Please Select an Option', disabled: true },
                        { issue: 'Zoom Appointment Issue' },
                        { issue: 'Technology Assistance - For Me' },
                        { issue: 'Technology Assistance - For Patient' },
                        { issue: 'Rescheduling Assistance' },
                        { issue: 'Request Item for Patient' },
                      ]}
                        // we don't tack care team mixpanel events
                      mixpanelTrack={() => { }}
                    />
                )}
        </S.Container>
    );
};

export default Support;
