import React, { useEffect } from 'react';
import * as S from '../../index.styles';
import { Inputs } from '@apps/common-ui';
import { ExerciseTypes } from '@apps/common-utilities';

const SubmissionOptions = ({
    register,
    watch,
    setValue,
    editing
}: {
  register: any;
  watch: any;
  setValue: any;
  editing?: boolean;
}) => {
    useEffect(() => {
        if (!editing) {
            if (watch('exerciseVariable.type') === ExerciseTypes.ExerciseType.SETS_AND_BREATHS) {
                setValue('submissionOptions.requireRMTDifficulty', true);
                setValue('submissionOptions.requireInhaleResistance', true);
                setValue('submissionOptions.requireExhaleResistance', true);
            }
        }
        if (watch('exerciseVariable.type') !== ExerciseTypes.ExerciseType.SETS_AND_BREATHS) {
            setValue('submissionOptions.requireRMTDifficulty', false);
            setValue('submissionOptions.requireInhaleResistance', false);
            setValue('submissionOptions.requireExhaleResistance', false);
        }
    }, [watch('exerciseVariable.type')]);
    return (
        <>
            <S.FormSectionTitle>📝 Submission Options</S.FormSectionTitle>
            <S.FormSectionSubtitle>How should this exercise be submitted?</S.FormSectionSubtitle>
            <S.FormRow>
                <S.FormRow direction="column">
                    <S.TargetsCheckbox>
                        <Inputs.Input {...register('submissionOptions.requireExercise')} type="checkbox" id="require-exercise" />
                        <Inputs.Label htmlFor="require-exercise">Require Activity</Inputs.Label>
                    </S.TargetsCheckbox>
                    <S.TargetsCheckbox>
                        <Inputs.Input {...register('submissionOptions.requireHeartRate')} type="checkbox" id="require-heart-rate" />
                        <Inputs.Label htmlFor="require-heart-rate">Require Heart Rate</Inputs.Label>
                    </S.TargetsCheckbox>
                    <S.TargetsCheckbox>
                        <Inputs.Input {...register('submissionOptions.requireSpO2Immediate')} type="checkbox" id="require-spo2-immediate" />
                        <Inputs.Label htmlFor="require-spo2-immediate">Require SpO2 Immediate</Inputs.Label>
                    </S.TargetsCheckbox>
                    <S.TargetsCheckbox>
                        <Inputs.Input {...register('submissionOptions.requireSpO2Lowest')} type="checkbox" id="require-spo2-lowest" />
                        <Inputs.Label htmlFor="require-spo2-lowest">Require SpO2 Lowest</Inputs.Label>
                    </S.TargetsCheckbox>
                    <S.TargetsCheckbox>
                        <Inputs.Input {...register('submissionOptions.requireOxygen')} type="checkbox" id="require-oxygen" />
                        <Inputs.Label htmlFor="require-oxygen">Require Oxygen</Inputs.Label>
                    </S.TargetsCheckbox>
                    <S.TargetsCheckbox>
                        <Inputs.Input {...register('submissionOptions.requireBreathlessness')} type="checkbox" id="require-breathlessness" />
                        <Inputs.Label htmlFor="require-breathlessness">Require Breathlessness</Inputs.Label>
                    </S.TargetsCheckbox>
                </S.FormRow>
                <S.FormRow direction="column">
                    <S.TargetsCheckbox>
                        <Inputs.Input
                          readOnly={watch('exerciseVariable.type') !== ExerciseTypes.ExerciseType.SETS_AND_BREATHS}
                          {...register('submissionOptions.requireRMTDifficulty')}
                          type="checkbox"
                          id="require-rmt-difficulty"
                        />
                        <Inputs.Label htmlFor="require-rmt-difficulty">Require RMT Difficulty</Inputs.Label>
                    </S.TargetsCheckbox>
                    <S.TargetsCheckbox>
                        <Inputs.Input
                          readOnly={watch('exerciseVariable.type') !== ExerciseTypes.ExerciseType.SETS_AND_BREATHS}
                          {...register('submissionOptions.requireInhaleResistance')}
                          type="checkbox"
                          id="require-rmt-inhale-resistance"
                        />
                        <Inputs.Label htmlFor="require-rmt-inhale-resistance">Require RMT Inhale Resistance</Inputs.Label>
                    </S.TargetsCheckbox>
                    <S.TargetsCheckbox>
                        <Inputs.Input
                          readOnly={watch('exerciseVariable.type') !== ExerciseTypes.ExerciseType.SETS_AND_BREATHS}
                          {...register('submissionOptions.requireExhaleResistance')}
                          type="checkbox"
                          id="require-rmt-exhale-resistance"
                        />
                        <Inputs.Label htmlFor="require-rmt-exhale-resistance">Require RMT Exhale Resistance</Inputs.Label>
                    </S.TargetsCheckbox>
                </S.FormRow>
            </S.FormRow>
        </>
    );
};

export default SubmissionOptions;
