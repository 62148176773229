import React, { memo, useEffect } from 'react';
import { Buttons } from '@apps/common-ui';
import { useForm } from 'react-hook-form';
import * as S from './index.styles';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from 'draft-js';
import MainExerciseDetails from './components/MainExerciseDetails';
import Equipment from './components/Equipment';
import ExerciseVariable from './components/ExerciseVariable';
import Targets from './components/Targets';
import { mapFormDataToExercise } from './utils';
import { RawExerciseFormData } from './types';
import { ExerciseTypes } from '@apps/common-utilities';
import SubmissionOptions from './components/SubmissionOptions';

type Props = {
    defaultValues?: RawExerciseFormData;
    editing?: boolean;
    onSubmit: (data: ExerciseTypes.IExercise) => void;
}

const ExerciseForm = ({ defaultValues, editing, onSubmit }: Props) => {
    const { handleSubmit, register, formState: { errors }, getValues, watch, control, setValue } = useForm({
        defaultValues,
        mode: 'onBlur'
    });

    const isSubmissionValid = () => {
        const formData = getValues();
        if (formData.equipment.ASSISTED_DEVICES.selected && !formData.equipment.ASSISTED_DEVICES.specification) {
            return false;
        }
        return true;
    };

    const onFormSubmit = async (data: any) => {
        if (isSubmissionValid()) {
            const formattedFormData = await mapFormDataToExercise(data);
            onSubmit(formattedFormData);
        }
    };

    const formSectionProps = {
        errors,
        register,
        watch,
        setValue,
    };

    return (
        <S.Form onSubmit={handleSubmit(onFormSubmit)}>
            <MainExerciseDetails
              {...formSectionProps}
              control={control}
              editing={editing}
              initialCategory={defaultValues?.category}
            />
            <hr />
            <Equipment {...formSectionProps} />
            <hr />
            <ExerciseVariable {...formSectionProps} editing={editing} />
            {watch('exerciseVariable.type') === ExerciseTypes.ExerciseType.INTERVALS && <p>Displayed as: <b>{watch('exerciseVariable.intervals.reps')} intervals of {watch('exerciseVariable.intervals.interval')} {watch('exerciseVariable.intervals.unit')?.toLowerCase()}</b></p>}
            <hr />
            <Targets {...formSectionProps} />
            <SubmissionOptions {...formSectionProps} editing={editing} />
            <S.ButtonsContainer>
                <Buttons.Button type="submit">Save Exercise</Buttons.Button>
                <Buttons.LinkButton to={-1} buttonType="tertiary">Discard</Buttons.LinkButton>
            </S.ButtonsContainer>
        </S.Form>
    );
};

export default ExerciseForm;
