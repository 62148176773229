import React, { useEffect, useState, useRef } from 'react';
import * as S from './index.styles';
import { TimerState, useTimerStateMachine } from './useTimerStateMachine';
import { Spinner, useModal, Buttons } from '@apps/common-ui';
import TimerPopup from '../TimerPopup';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../state/store';
import { currentMonthAndYear, currentMonth } from './utils';
import { useActionLoader } from '../../../../hooks/useActionLoader';
import { fetch98976CurrentPeriodSummary, fetchBillableSessionTotalSummary } from '../../../../state/reducers/billing';
import { useParams } from 'react-router';
import { UserRoles } from '../../../../types/models';
import AddEntryModal from '../../../TimeTracking/AddEntryModal';
import { isSameMonth } from 'date-fns';
import { NaiveDate } from '../../../../utils/dateUtils';

const CareTeamTimer = ({ expanded }: {expanded: boolean }) => {
    const { state, time, finalTime, timerActions, loading, popupInterval, stopInterval } = useTimerStateMachine(TimerState.NOT_STARTED);
    const { patientId } = useParams();
    const { closeModal, isOpen, openModal } = useModal();
    const { closeModal: closeAddModal, isOpen: isAddModalOpen, openModal: openAddModal } = useModal();
    const [popupTimeout, setPopupTimeout] = useState<ReturnType<typeof setTimeout> | null>(null);
    const [clearTimeoutInModal, setClearTimeoutInModal] = useState(false);
    const { user: provider } = useSelector((store: RootState) => store.session);
    const { totalBillingSummary } = useSelector((store: RootState) => store.billing);
    const [isPT, setIsPT] = useState(false);
    const { callAction: getTotalSummary, done: totalSummaryDone, loading: totalSummaryLoading } = useActionLoader(fetchBillableSessionTotalSummary);
    const [currentMonthSummary, setCurrentMonthSummary] = useState(totalBillingSummary[0]);
    const [hasMaxUnits, setHasMaxUnits] = useState(totalBillingSummary[0].totalBillingUnits > totalBillingSummary[0].maxBillingUnits);

    // need to use ref to keep track of the current value of isOpen in onBlur event
    const openRef = useRef<boolean>(isOpen);

    // need to use ref to keep track of the current value of popupTimeout in onBlur event
    const popupTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(popupTimeout);

    // need to use these refs to keep track of the current value of popupInterval and stopInterval in onFocus event
    const popupIntervalRef = useRef<number>(popupInterval);
    const stopIntervalRef = useRef<number>(stopInterval);
    const stateRef = useRef<TimerState>(state);

    const setCurrentMonthBillableSessionTotalData = () => {
        // find the current month's total billing summary, if it doesn't exist use the lastest month available
        const currentMonthTotal = totalBillingSummary.find((summary) => summary.sessions.length > 0 && isSameMonth(new NaiveDate(summary.sessions[0].calendarDate), new Date())) || totalBillingSummary[0];
        if (currentMonthTotal) {
            setCurrentMonthSummary(currentMonthTotal);
            setHasMaxUnits(currentMonthTotal.totalBillingUnits > currentMonthTotal.maxBillingUnits);
        }
    };

    const startPopupTimeout = (isInitialPopup: boolean) => {
        // should not set an interval if either popupInterval or stopInterval is 0
        if (popupIntervalRef.current === 0 || stopIntervalRef.current === 0) {
            return;
        }
        // clear timeout if one already exists
        if (popupTimeout) {
            clearTimeout(popupTimeout);
        }
        // if this is the first time the popup is shown for this session,
        // account for the 10 seconds before the session creation call was made
        setPopupTimeout(setTimeout(() => {
            setClearTimeoutInModal(false);
            openModal();
        }, (popupIntervalRef.current * 1000 * 60) - (stopIntervalRef.current * 1000) - (isInitialPopup ? 10000 : 0)));
    };

    const getDisplayTime = (timeValue?: number) => {
        const timeToUse = timeValue || time;
        const minutes = Math.floor(timeToUse / 60);
        const seconds = timeToUse % 60;
        let secondsString = seconds.toString();
        let minutesString = minutes.toString();

        if (minutes < 10) {
            minutesString = `0${minutes}`;
        }
        if (seconds < 10) {
            secondsString = `0${seconds}`;
        }
        return `${minutesString}:${secondsString}`;
    };

    const manuallyToggleTimer = () => {
        if (state === TimerState.RUNNING) {
            timerActions.manuallyStopTimer();
            if (popupTimeout) {
                clearTimeout(popupTimeout);
            }
        } else {
            timerActions.restartTimer();
            startPopupTimeout(false);
        }
    };

    const continueTimerClicked = () => {
        // if continue button is clicked while timer is running, close the modal and let timer continue running
        // else close the modal and restart the timer since it has been stopped.
        if (state !== TimerState.RUNNING) {
            timerActions.restartTimer();
        }
        startPopupTimeout(false);
    };

    // if stop button is clicked, stop the timer (if not already stopped) and close the modal
    const stopTimerClicked = () => {
        if (state === TimerState.RUNNING) {
            timerActions.manuallyStopTimer();
            if (popupTimeout) {
                clearTimeout(popupTimeout);
            }
        }
    };

    // if the window loses focus while the modal is open, stop the timer
    const onBlur = () => {
        if (openRef.current) {
            // since the timer has been stopped clear the stop timer timeout inside the modal
            setClearTimeoutInModal(true);
            timerActions.manuallyStopTimer();
        } else if (popupTimeoutRef.current && isPT) {
            // Since the timer will reset if the window loses focus while the modal is not open,
            // we need to clear the timeout that would have opened the modal
            clearTimeout(popupTimeoutRef.current);
        }
    };

    const onFocus = () => {
        // Need to set the popup timeout if the window lost focus while the modal wasn't open
        if (!openRef.current && stateRef.current !== TimerState.MANUALLY_STOPPED) {
            startPopupTimeout(false);
        }
    };

    const getTimerText = () => {
        if (loading) {
            return <Spinner small />;
        }
        return state === TimerState.STOPPED || state === TimerState.MANUALLY_STOPPED ? 'Start' : 'Stop';
    };

    useEffect(() => {
        if (!totalSummaryDone && !totalSummaryLoading) {
            getTotalSummary(patientId);
        }
    }, [patientId, totalSummaryDone, totalSummaryLoading]);

    // We can only start the first timeout when the popupInterval and stopInterval become defined after the initial create call
    useEffect(() => {
        popupIntervalRef.current = popupInterval;
        stopIntervalRef.current = stopInterval;
        if (popupInterval > 0 && stopInterval > 0 && state === TimerState.RUNNING) {
            startPopupTimeout(true);
        }
    }, [popupInterval, stopInterval]);

    useEffect(() => {
        openRef.current = isOpen;
    }, [isOpen]);

    useEffect(() => {
        if (popupTimeoutRef.current) {
            clearTimeout(popupTimeoutRef.current);
        }
        popupTimeoutRef.current = popupTimeout;
    }, [popupTimeout]);

    useEffect(() => {
        stateRef.current = state;
        // clear the timeout when the timer state is stopped or manually stopped
        if (state === TimerState.STOPPED || state === TimerState.MANUALLY_STOPPED) {
            if (popupTimeout) {
                clearTimeout(popupTimeout);
            }
            if (popupTimeoutRef.current) {
                clearTimeout(popupTimeoutRef.current);
            }
        }
    }, [state]);

    useEffect(() => {
        if (isPT) {
            window.addEventListener('blur', onBlur);
            window.addEventListener('focus', onFocus);
        }

        return () => {
            if (isPT) {
                window.removeEventListener('blur', onBlur);
                window.removeEventListener('focus', onFocus);
            }
        };
    }, [isPT]);

    useEffect(() => {
        if (provider?.roles.find(role => role.billable)?.role === UserRoles.PHYSICAL_THERAPIST) {
            setIsPT(true);
        }
    }, [provider]);

    useEffect(() => {
        setCurrentMonthBillableSessionTotalData();
    }, [totalBillingSummary]);

    return (
        <>
            <TimerPopup
              showModal={isOpen}
              dismissModal={closeModal}
              continueClicked={continueTimerClicked}
              stopClicked={stopTimerClicked}
              popupInterval={popupIntervalRef.current}
              timerValue={getDisplayTime()}
              finalTimerValue={getDisplayTime(finalTime)}
              stopTimerInterval={stopIntervalRef.current}
              stopTimer={timerActions.manuallyStopTimer}
              shouldClearTimeout={clearTimeoutInModal}
              state={state}
            />
            {isAddModalOpen && (
                <AddEntryModal
                  showModal={isAddModalOpen}
                  dismissModal={closeAddModal}
                  patientId={patientId || ''}
                  provider={provider}
                  refreshSessions={() => getTotalSummary(patientId)}
                />
            )}
            <S.TimerContainer expanded={expanded}>
                <S.Timer>
                    <S.TimerNumbers state={state}>{getDisplayTime()}</S.TimerNumbers>
                    <S.TimerButton type="button" onClick={manuallyToggleTimer} state={state}>
                        {getTimerText()}
                    </S.TimerButton>
                </S.Timer>
                <S.Header>{currentMonth()} {isPT ? 'RTM' : 'PCM'}</S.Header>
                <S.MonthlySummary>
                    <div>
                        <S.Text>Async:</S.Text>
                        <S.Text>Sync:</S.Text>
                    </div>
                    <div>
                        <S.Text>{Math.floor(currentMonthSummary.totalAsyncSeconds / 60)} min</S.Text>
                        <S.Text>{Math.floor(currentMonthSummary.totalSyncSeconds / 60)} min</S.Text>
                    </div>
                </S.MonthlySummary>
                <S.BillingSummary>
                    <div>
                        <S.Text
                          error={hasMaxUnits}
                        >
                            Total:
                        </S.Text>
                        <S.Text
                          error={hasMaxUnits}
                        >
                            Units:
                        </S.Text>
                    </div>
                    <S.TextContainer>
                        <S.Text error={hasMaxUnits}>
                            {Math.floor(currentMonthSummary.totalSeconds / 60)}/{Math.floor(currentMonthSummary.maxSeconds / 60)}min
                        </S.Text>
                        <S.Text error={hasMaxUnits}>
                            {currentMonthSummary.totalBillingUnits}/{currentMonthSummary.maxBillingUnits}
                        </S.Text>
                    </S.TextContainer>
                </S.BillingSummary>
                {hasMaxUnits && (
                    <S.Error error>Maximum billing time reached for {currentMonthAndYear()}</S.Error>
                )}
                <Buttons.Button
                  buttonType="tertiary"
                  style={{ margin: '0', padding: '0', textAlign: 'left' }}
                  onClick={openAddModal}
                >
                    Add Manual Entry
                </Buttons.Button>
            </S.TimerContainer>
        </>
    );
};

export default CareTeamTimer;
