import React, { useState } from 'react';
import ConfigurationService from '../../services/ConfigurationService';
import { types } from '@apps/common-utilities';
import toast from 'react-hot-toast';
import { Spinner } from '@apps/common-ui';

const Resources = () => {
    const [resourcesSrc, setResourcesSrc] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);

    const fetchResources = () => {
        ConfigurationService.getConfiguration('CARE_TEAM_RESOURCES_URL').then((response: types.Configuration) => {
            setResourcesSrc(response.configurationValue);
        }).catch(() => {
            toast.error('Failed to load resources');
        }).finally(() => {
            setLoading(false);
        });
    };

    if (!resourcesSrc || loading) {
        fetchResources();
        return <Spinner />;
    }
    return (
        <div>
            {resourcesSrc && !loading && (
                <iframe
                  title="Resources"
                  src={resourcesSrc}
                  style={{ width: '100%', height: '100vh', border: 'none' }}

                />
            )}

        </div>
    );
};

export default Resources;
