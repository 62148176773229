import { Buttons, Inputs, useConfirmDialog, VideoEmbed, Spinner, Card } from '@apps/common-ui';
import { LearningTypes, slugify } from '@apps/common-utilities';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Routes } from '../../api/Routes';
import { PageHeader } from '../../components/common/commonStyled';
import LessonContentInput from '../../components/LessonContentInput';
import { RootState } from '../../state/store';
import * as S from './index.styles';
import { getWordCountFromHtml } from './utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { isVimeoUrl } from '../../utils/url';
import { TenantApi } from '../../api/CoachingAPI';
import { IVideoGenerationJob, VideoGenerationJobStatus } from '../../types/models';
import { ApiResponse, RequestMethod, useApiRequest } from '../../hooks/useApiRequest';

type RouteParams = {
    lessonId?: string;
    moduleId: string;
    moduleName: string;
}

const ManageLesson = () => {
    const { register, control, watch, formState: { errors }, handleSubmit, setValue, getValues } = useForm();
    const { lessonId, moduleId, moduleName } = useParams<RouteParams>();
    const [lesson, setLesson] = useState<LearningTypes.ILesson>();
    const [loading, setLoading] = useState(true);
    const [wordCount, setWordCount] = useState(0);
    const readableModuleName = moduleName && slugify.fromSlug(moduleName);
    const navigate = useNavigate();
    const [previewing, setPreviewing] = useState(false);
    const [rawContent, setRawContent] = useState('');
    const pollId = useRef<ReturnType<typeof setInterval> | null>(null);
    const [videoGenerationTimeout, setVideoGenerationTimeout] = useState<ReturnType<typeof setTimeout> | null>(null);
    const [videoJob, setVideoJob] = useState<IVideoGenerationJob | null>(null);
    const { callApi: createVideoJob } = useApiRequest<IVideoGenerationJob>(RequestMethod.POST);
    const { callApi: getVideoJob } = useApiRequest<IVideoGenerationJob>(RequestMethod.GET);

    const fetchLesson = async (lessonIdToGet: string) => {
        const fetchedLesson = await TenantApi.get(`/${Routes.coaches}/${Routes.education}/lessons/${lessonIdToGet}`);
        setLesson(fetchedLesson);
        setValue('title', fetchedLesson.title);
        setValue('content', fetchedLesson.content);
        setValue('videoUrl', fetchedLesson.videoUrl);
        setWordCount(getWordCountFromHtml(fetchedLesson.content));
        setLoading(false);
    };

    useEffect(() => {
        if (lessonId) {
            fetchLesson(lessonId);
        } else {
            setLoading(false);
        }
    }, [lessonId]);

    useEffect(() => {
        if (watch('content')) {
            setWordCount(getWordCountFromHtml(watch('content')));
        }
    }, [watch('content')]);

    useEffect(() => {
        return () => {
            if (pollId.current) {
                clearInterval(pollId.current);
            }
            if (videoGenerationTimeout) {
                clearTimeout(videoGenerationTimeout);
            }
        };
    }, []);

    const { ConfirmationDialog, confirm } = useConfirmDialog({
        message: 'Are you sure you want to delete this lesson?',
        cancelType: 'danger'
    });

    const confirmDelete = (e: any) => {
        e.preventDefault();
        confirm().then((result) => {
            if (result) {
                TenantApi.delete(`/${Routes.coaches}/${Routes.education}/modules/${moduleId}/lessons/${lessonId}`).then(res => {
                    toast.success('Lesson deleted successfully');
                    navigate(-1);
                });
            }
        });
    };
    // For some reason the camera emoji is being added when saving a lesson with images in the content...
    const removeCameraIcon = (content: string) => content.replaceAll('📷', '');

    const onUpdateLesson = (data: any) => {
        const lessonPayload: LearningTypes.ILesson = {
            ...lesson,
            ...data
        };
        lessonPayload.content = removeCameraIcon(lessonPayload.content);
        TenantApi.put(`/${Routes.coaches}/${Routes.education}/modules/${moduleId}/lessons/${lessonId}`, lessonPayload).then(res => {
            toast.success('Lesson saved successfully');
            navigate(-1);
        });
    };

    const onCreateLesson = (data: any) => {
        const payload: LearningTypes.ILesson = {
            ...data,
            moduleId: Number(moduleId),
        };
        payload.content = removeCameraIcon(payload.content);
        TenantApi.post(`/${Routes.coaches}/${Routes.education}/modules/${moduleId}/lessons`, payload).then(res => {
            toast.success('Lesson saved successfully');
            navigate(-1);
        });
    };

    const onSave = (data: any) => {
        if (lessonId) {
            onUpdateLesson(data);
        } else {
            onCreateLesson(data);
        }
    };

    const togglePreviewing = (e: any) => {
        e.preventDefault();
        setPreviewing(!previewing);
    };

    const cancelEdit = (e: any) => {
        e.preventDefault();
        navigate(-1);
    };

    const pollForVideo = async (createdJob: IVideoGenerationJob) => {
        if (!createdJob.id) {
            return;
        }
        const res: ApiResponse<IVideoGenerationJob> = await getVideoJob(`/generate/${createdJob.id}`);
        if (res.error.error) {
            toast.error(res.error.message);
            return;
        }
        const videoStatus: IVideoGenerationJob = res.response.data;
        setVideoJob(videoStatus);
        // generation done, stop polling and set video url
        if (videoStatus.status === VideoGenerationJobStatus.COMPLETED && pollId.current) {
            clearInterval(pollId.current);
            pollId.current = null;
            setValue('videoUrl', videoStatus.draftVideoUrl);
        } else if (videoStatus.status === VideoGenerationJobStatus.FAILED && pollId.current) {
            clearInterval(pollId.current);
            pollId.current = null;
            toast.error('Video generation failed. Please try again');
        }
    };

    const generateVideo = async (regenerate: boolean) => {
        let generateEndpoint = '/generate/audio';
        if (regenerate && videoJob) {
            generateEndpoint = `/generate/video/${videoJob.id}/regenerate`;
        }
        const payload = {
            content: JSON.stringify(removeCameraIcon(rawContent).trim()).replace('\\u0001', '\\n'),
            title: watch('title')
        };
        const res: ApiResponse<IVideoGenerationJob> = await createVideoJob(generateEndpoint, payload);
        if (res.error.error) {
            toast.error(res.error.message);
            return;
        }
        const createdJob = res.response.data;
        setVideoJob(createdJob);
        // clear previous timers if exists
        if (pollId.current) {
            clearInterval(pollId.current);
        }
        if (videoGenerationTimeout) {
            clearTimeout(videoGenerationTimeout);
        }

        // Timeout after 10 minutes
        setVideoGenerationTimeout(setTimeout(() => {
            toast.error('Video generation timed out. Please try again');
            if (pollId.current) {
                clearInterval(pollId.current);
            }
        }, 600000));
        // Poll for video every 5 seconds
        pollId.current = setInterval(async () => {
            pollForVideo(createdJob);
        }, 5000);
    };

    if (loading) {
        return <Spinner />;
    }

    if (previewing) {
        const contentWithCameraIcon = watch('content');
        const formattedContent = removeCameraIcon(contentWithCameraIcon);
        return (
            <S.LessonPreviewContainer>
                <Buttons.Button buttonType="tertiary" onClick={togglePreviewing}>
                    <FontAwesomeIcon icon={faArrowLeft as IconProp} />
                    Back
                </Buttons.Button>
                <h1>{watch('title')}</h1>
                <S.LessonPreviewContent dangerouslySetInnerHTML={{ __html: formattedContent }} />
            </S.LessonPreviewContainer>
        );
    }

    return (
        <S.LessonForm onSubmit={handleSubmit(onSave)}>
            <ConfirmationDialog />
            <PageHeader>
                <S.Titles>
                    <h2>Manage Lesson</h2>
                </S.Titles>
                {lessonId && <Buttons.Button onClick={confirmDelete} buttonStyle="danger" buttonType="tertiary">Delete Lesson</Buttons.Button>}
            </PageHeader>
            <Inputs.InputContainer error={errors.title}>
                <Inputs.Label>Lesson Name</Inputs.Label>
                <Inputs.Input
                  id="title"
                  placeholder="Lesson Name"
                  inputSize={Inputs.InputSize.fullWidth}
                  {...register('title', { required: true })}
                />
            </Inputs.InputContainer>
            <Inputs.InputContainer>
                <Inputs.Label>Module</Inputs.Label>
                <S.ModuleName>{readableModuleName}</S.ModuleName>
            </Inputs.InputContainer>
            <S.LessonContentContainer>
                <S.LessonContentHeader>
                    <Inputs.Label>Lesson Content</Inputs.Label>
                    <span><S.WordCount isOver={wordCount > 400}>{wordCount} words </S.WordCount>(max of 400 recommended)</span>
                </S.LessonContentHeader>
                <Controller
                  name="content"
                  control={control}
                  defaultValue={null}
                  render={({ field: { onChange, value } }) => (
                      <LessonContentInput
                        onChange={(c, rawTextContent) => {
                            onChange(c);
                            setRawContent(rawTextContent);
                        }}
                        defaultValue={value || lesson?.content}
                      />
                  )}
                />
            </S.LessonContentContainer>
            <S.Titles>
                <h2>Lesson Audio</h2>
            </S.Titles>
            <S.Card>
                {!pollId.current ? (
                    <>
                        <S.GenerateVideoContainer>
                            <S.VideoLabel>Add Lesson Audio Manually</S.VideoLabel>
                            <S.VideoInput
                              id="lessonVideoUrl"
                              placeholder="Lesson Video URL"
                              error={errors.videoUrl}
                              {...register('videoUrl', {
                                validate: (u) => (!isVimeoUrl(u as string) && u as string !== '')
                                    ? 'Video URL must be a valid Vimeo URL or empty'
                                    : true
                            })}
                            />
                        </S.GenerateVideoContainer>
                        <Inputs.ErrorMessage>{errors.videoUrl?.message}</Inputs.ErrorMessage>
                    </>
            )
            : (
                <S.GenerateVideoContainer>
                    <S.GenerateVideoRow>
                        <S.GenerateVideoColumn>
                            <h3>Generating video...</h3>
                            <h4>Status: {videoJob?.status}</h4>
                        </S.GenerateVideoColumn>
                        <S.SpinnerContainer>
                            <Spinner />
                        </S.SpinnerContainer>
                    </S.GenerateVideoRow>
                </S.GenerateVideoContainer>
            )}
                {!pollId.current && watch('videoUrl') && (
                <>
                    <p>Please review the video below:</p>
                    <S.GenerateVideoContainer style={{ height: '400px' }}>
                        <VideoEmbed
                          height="300px"
                          title="Lesson Video Preview"
                          src={watch('videoUrl')}
                        />
                        <S.ButtonsContainer>
                            <strong>Poor quality or inaccurate generated video?</strong>
                            <Buttons.Button
                              type="button"
                              onClick={() => generateVideo(true)}
                            >
                                Regenerate
                            </Buttons.Button>
                        </S.ButtonsContainer>
                    </S.GenerateVideoContainer>
                </>
            )}
                {!pollId.current && !watch('videoUrl') && (
                <S.GenerateVideoContainer>
                    <S.VideoLabel>Generate Lesson Audio</S.VideoLabel>
                    <S.VideoButton
                      type="button"
                      size="medium"
                      onClick={() => generateVideo(false)}
                    >
                        Generate
                    </S.VideoButton>
                    <p>Note: It costs us to generate so please verify text above before generation</p>
                </S.GenerateVideoContainer>
            )}
            </S.Card>
            <S.ButtonsContainer>
                <S.ButtonsGroup>
                    <Buttons.Button type="submit">Save Lesson</Buttons.Button>
                    <Buttons.Button buttonType="tertiary" onClick={cancelEdit}>Discard Changes</Buttons.Button>
                </S.ButtonsGroup>
                <Buttons.Button buttonType="tertiary" onClick={togglePreviewing}>Preview</Buttons.Button>
            </S.ButtonsContainer>
        </S.LessonForm>
    );
};

export default ManageLesson;
