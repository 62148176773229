import React, { useEffect, useState } from 'react';
import * as S from './index.styles';
import { useNavigate, useParams } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronLeft, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useModal, Inputs } from '@apps/common-ui';
import DuplicateModal from './components/DuplicateModal';
import { LearningTypes } from '@apps/common-utilities';
import { ApiResponse, RequestMethod, useApiRequest } from '../../hooks/useApiRequest';
import { monthDateYear, NaiveDate } from '../../utils/dateUtils';
import { useForm } from 'react-hook-form';
import DeleteModuleModal from './components/DeleteModuleModal';
import { ISubModuleSummary, IUpdateModule } from '@apps/common-utilities/src/types/learningTypes';
import { uploadFile } from '../../utils/fileUpload';
import { useSelector } from 'react-redux';
import { RootState } from '../../state/store';
import { useActionLoader } from '../../hooks/useActionLoader';
import { fetchModules } from '../../state/reducers/learning';
import toast from 'react-hot-toast';

const ImagePreview = ({ files = [] }: { files: File[] | null }) => {
    if (!files || files.length === 0) {
        return null;
    }
    const imageFile = files[0];
    const src = URL.createObjectURL(imageFile);

    return (
        <img src={src} alt="Module" width="100" height="100" />
    );
};

const ManageModule = () => {
    const { moduleId } = useParams<{ moduleId: string }>();
    const navigate = useNavigate();
    const [module, setModule] = useState<LearningTypes.IModuleDetails>();
    const [submodulesState, setSubmodulesState] = useState<ISubModuleSummary[]>([]);
    const modules = useSelector((state: RootState) => state.learning.modules);
    const [existingModuleTitles, setExistingModuleTitles] = useState<string[]>([]);
    const [uploadLoading, setUploadLoading] = useState(false);

    const { callAction: getModules, done: modulesFetched } = useActionLoader(fetchModules);

    const { register, getValues, watch, formState: { errors }, handleSubmit, setError, setValue } = useForm({
        defaultValues: {
            moduleName: module?.title || '',
            internalName: module?.internalTitle || '',
            imageFile: null,
        } || {},
    });

    const { isOpen: isDuplicateModalOpen, openModal: openDuplicateModal, closeModal: closeDuplicateModal } = useModal();
    const { isOpen: isDeleteModalOpen, openModal: openDeleteModal, closeModal: closeDeleteModal } = useModal();
    const [selectedSubmodule, setSelectedSubmodule] = useState<LearningTypes.ISubModuleSummary>();
    const { callApi: getModuleDetails } = useApiRequest<LearningTypes.IModuleDetails>(RequestMethod.GET);
    const { callApi: createModule } = useApiRequest<LearningTypes.IModule>(RequestMethod.POST);
    const { callApi: updateModule } = useApiRequest<LearningTypes.IModule>(RequestMethod.PUT);

    const checkDuplicateTitle = (title: string) => {
        if (module?.title?.toLowerCase() === title.toLowerCase()) {
            return false; // Allow if the title is unchanged
        }
        return existingModuleTitles.some(existingTitle => existingTitle.toLowerCase() === title.toLowerCase());
    };

    const changeSubmoduleOrder = (oldIndex: number, newIndex: number) => {
        const newSubmodulesState = submodulesState.slice();
        newSubmodulesState[oldIndex] = submodulesState[newIndex];
        newSubmodulesState[newIndex] = submodulesState[oldIndex];
        setSubmodulesState(newSubmodulesState);
    };

    const fetchModule = async () => {
        if (moduleId) {
            getModuleDetails(`/coaches/education/modules/${moduleId}/details`).then((response) => {
                setModule(response.response.data);
            });
        }
    };

    const formatModuleForUpdate = async (data: any) => {
        if (data.imageFile && data.imageFile.length > 0) {
            setUploadLoading(true);
            const fileUrl = await uploadFile(data.imageFile[0]);
            data.thumbnailUrl = fileUrl;
        }
        const moduleData: IUpdateModule = {
            id: module?.id || 0,
            title: data.moduleName,
            thumbnailUrl: data.thumbnailUrl || module?.thumbnailUrl,
            internalTitle: data.internalName,
            subModuleIds: submodulesState.map((submodule) => submodule.id),
        };
        return moduleData;
    };

    const formatModuleForCreate = async (data: any) => {
        setUploadLoading(true);
        const fileUrl = await uploadFile(data.imageFile[0]);
        if (!fileUrl) {
            setUploadLoading(false);
        }
        const moduleData: LearningTypes.ICreateModule = {
            title: data.moduleName,
            thumbnailUrl: fileUrl,
            internalTitle: data.internalName,
        };
        return moduleData;
    };

    const saveModuleChanges = async (data: any) => {
        // check for errors in data before saving
        if (!data) {
            return;
        }
        if (checkDuplicateTitle(data.moduleName)) {
            setError('moduleName', {
                type: 'duplicate',
                message: 'Module title already exists',
            });
            return;
        }
        if (!module?.thumbnailUrl && (!data.imageFile || data.imageFile.length === 0)) {
            setError('imageFile', {
                type: 'required',
                message: 'Image is required',
            });
            return;
        }

        let submissionData;

        if (module?.id) {
            // update the module including the submodule order
            submissionData = await formatModuleForUpdate(data);
            updateModule(`/coaches/education/modules/${module?.id}`, submissionData).then(() => {
                toast.success('Module updated successfully');
                getModuleDetails(`/coaches/education/modules/${module?.id}/details`).then(({ response, error }: ApiResponse<LearningTypes.IModuleDetails>) => {
                    setModule(response.data);
                });
            });
        } else {
            // create the module
            submissionData = await formatModuleForCreate(data);
            let newModuleId;
            createModule('/coaches/education/modules', submissionData).then((res) => {
                newModuleId = res.response.data.id;
                if (newModuleId) {
                    toast.success('Module created successfully');
                    navigate(`/modal/modules/manage/${newModuleId}`);
                }
            });
        }
        // Fetch modules to keep track of existing module titles
        getModules();
    };

    const editSubmoduleClicked = (submodule: LearningTypes.ISubModuleSummary) => {
        if (module) {
            if (submodule.subModuleType === LearningTypes.SubModuleType.LESSON) {
                navigate(`/learning/modules/${module?.id}/${encodeURIComponent(module.internalTitle)}/lessons/${submodule.id}`);
            } else if (submodule.subModuleType === LearningTypes.SubModuleType.QUIZ) {
                navigate(`/learning/modules/${module?.id}/${encodeURIComponent(module.internalTitle)}/quizzes/${submodule.id}`);
            }
        }
    };

    useEffect(() => {
        if (moduleId && !module && !submodulesState.length) {
            fetchModule();
        }
    }, [moduleId]);

    useEffect(() => {
        if (module) {
            setValue('moduleName', module?.title);
            setValue('internalName', module?.internalTitle);
            if (module.subModules.length) {
                setSubmodulesState(module?.subModules);
            }
        }
    }, [module]);

    useEffect(() => {
        if (modulesFetched) {
            setExistingModuleTitles(modules.map((m) => m.title));
        }
    }, [modules]);

    return (
        <>
            <DeleteModuleModal showModal={isDeleteModalOpen} dismissModal={closeDeleteModal} moduleId={module?.id || 0} />
            <DuplicateModal
              showModal={isDuplicateModalOpen}
              dismissModal={closeDuplicateModal}
              refreshModule={fetchModule}
              moduleId={module?.id || 0}
              submodule={selectedSubmodule}
            />
            <S.Container>
                <S.TitleBar>
                    <S.BackButton onClick={() => navigate('/learning')}><FontAwesomeIcon icon={faChevronLeft as IconProp} /> Back</S.BackButton>
                </S.TitleBar>
                <S.PageContent>
                    <S.ModuleForm onSubmit={handleSubmit(saveModuleChanges)}>
                        <S.PageTitle>Module Overview</S.PageTitle>
                        <S.SectionRow>
                            <S.FieldsContainer>
                                <Inputs.InputContainer error={errors.moduleName}>
                                    <S.FieldLabel>Module Name</S.FieldLabel>
                                    <S.Field
                                      {...register('moduleName', { required: true })}
                                    />
                                </Inputs.InputContainer>
                            </S.FieldsContainer>
                            <S.PhotoContainer>
                                <Inputs.InputContainer error={errors.imageFile}>
                                    <S.FieldLabel>Module Photo</S.FieldLabel>
                                    <S.ModuleImageContainer>
                                        {watch('imageFile')
                                    ? <ImagePreview files={getValues('imageFile')} />
                                    : module?.thumbnailUrl && <img src={module?.thumbnailUrl} alt="Module" />}
                                        <Inputs.Input
                                          type="file"
                                          id="imageFile"
                                          accept="image/*"
                                          {...register('imageFile')}
                                        />
                                    </S.ModuleImageContainer>
                                </Inputs.InputContainer>
                            </S.PhotoContainer>
                        </S.SectionRow>
                        <S.SectionRow>
                            <S.FieldsContainer>
                                <Inputs.InputContainer error={errors.internalName}>
                                    <S.FieldLabel>Internal Name</S.FieldLabel>
                                    <S.Field
                                      {...register('internalName', { required: true })}
                                    />
                                </Inputs.InputContainer>
                            </S.FieldsContainer>
                            <S.StatsRow>
                                <S.StatsContainer>
                                    <S.StatsTitle>Assigned to</S.StatsTitle>
                                    <S.StatsValue>{module?.assignedToCount || 0}</S.StatsValue>
                                </S.StatsContainer>
                                <S.StatsContainer>
                                    <S.StatsTitle>Lessons</S.StatsTitle>
                                    <S.StatsValue>{module?.lessonCount || 0}</S.StatsValue>
                                </S.StatsContainer>
                                <S.StatsContainer>
                                    <S.StatsTitle>Quizzes</S.StatsTitle>
                                    <S.StatsValue>{module?.quizCount || 0}</S.StatsValue>
                                </S.StatsContainer>
                            </S.StatsRow>
                        </S.SectionRow>
                        <S.ModuleContentsContainer>
                            <S.ModuleContentsTitleRow>
                                <S.ModuleContentsTitle moduleExists={!!module}>
                                    Module Contents
                                </S.ModuleContentsTitle>
                                <S.TitleButtonsContainer>
                                    <S.AddQuizOrLessonButton
                                      type="button"
                                      moduleExists={!!module}
                                      disabled={!module}
                                      onClick={() => {
                                        if (module) {
                                            navigate(`/learning/modules/${module?.id}/${encodeURIComponent(module.internalTitle)}/quizzes/new`);
                                        }
                                      }}
                                    >
                                        + Add Quiz
                                    </S.AddQuizOrLessonButton>
                                    <S.AddQuizOrLessonButton
                                      type="button"
                                      moduleExists={!!module}
                                      disabled={!module}
                                      onClick={() => {
                                        if (module) {
                                            navigate(`/learning/modules/${module?.id}/${encodeURIComponent(module.internalTitle)}/lessons/new`);
                                        }
                                      }}
                                    >
                                        + Add Lesson
                                    </S.AddQuizOrLessonButton>
                                </S.TitleButtonsContainer>
                            </S.ModuleContentsTitleRow>
                            {!!module && !!submodulesState.length && (
                            <div>
                                {submodulesState.map((submodule, index) => (
                                    <S.SubmoduleRow>
                                        <S.SubmoduleRowStartContainer>
                                            <S.PositionButtonContainer>
                                                <S.SubmodulePositionButton
                                                  type="button"
                                                  isFirstOrLast={index === 0}
                                                  onClick={() => {
                                                      if (index !== 0) {
                                                          changeSubmoduleOrder(index, index - 1);
                                                      }
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faChevronUp as IconProp} />
                                                </S.SubmodulePositionButton>
                                                <S.SubmodulePositionButton
                                                  type="button"
                                                  isFirstOrLast={index === submodulesState.length - 1}
                                                  onClick={() => {
                                                          if (index !== submodulesState.length - 1) {
                                                              changeSubmoduleOrder(index, index + 1);
                                                          }
                                                      }}
                                                >
                                                    <FontAwesomeIcon icon={faChevronDown as IconProp} />
                                                </S.SubmodulePositionButton>
                                            </S.PositionButtonContainer>
                                            <S.SubmoduleTitle>{submodule.title}</S.SubmoduleTitle>
                                        </S.SubmoduleRowStartContainer>
                                        <S.SubmoduleRowEndContainer>
                                            <S.DuplicateButton
                                              type="button"
                                              onClick={() => {
                                                setSelectedSubmodule(submodule);
                                                openDuplicateModal();
                                            }}
                                            >
                                                Duplicate
                                            </S.DuplicateButton>
                                            <S.EditButton
                                              type="button"
                                              onClick={() => editSubmoduleClicked(submodule)}
                                            >
                                                Edit
                                            </S.EditButton>
                                        </S.SubmoduleRowEndContainer>
                                    </S.SubmoduleRow>
                                ))}
                            </div>
                            )}
                            {!module && (
                                <S.SaveModulePrompt>
                                    In order to add content to this module, please fill in the name, internal name, add a photo and then click &apos;Create Module&apos;
                                </S.SaveModulePrompt>
                            )}
                            <S.ModuleContentsBottomContainer>
                                <S.ModuleContentsButtonContainer>
                                    <S.SaveButton
                                      type="submit"
                                    >
                                        {module ? 'Save Changes' : 'Create Module'}
                                    </S.SaveButton>
                                    <S.DiscardButton
                                      type="button"
                                      onClick={() => navigate('/learning')}
                                    >
                                        {module ? 'Discard' : 'Cancel'}
                                    </S.DiscardButton>
                                    {!!module && (
                                    <S.DeleteButton
                                      type="button"
                                      onClick={openDeleteModal}
                                    >
                                        Delete Module
                                    </S.DeleteButton>
                                    )}
                                </S.ModuleContentsButtonContainer>
                                <S.LastUpdatedContainer>
                                    {!!module?.updatedAt && <S.LastUpdated>Last Updated {monthDateYear(new NaiveDate(module?.updatedAt))}</S.LastUpdated>}
                                </S.LastUpdatedContainer>
                            </S.ModuleContentsBottomContainer>
                        </S.ModuleContentsContainer>
                    </S.ModuleForm>
                </S.PageContent>
            </S.Container>
        </>
    );
};
export default ManageModule;
