import { Inputs, useConfirmDialog } from '@apps/common-ui';
import { ExerciseTypes, Utils, typeUtils } from '@apps/common-utilities';
import { differenceInCalendarWeeks, differenceInYears, format, getDaysInMonth, isPast } from 'date-fns';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { RootState } from '../../state/store';
import { ProgramStatusIndicator } from '../../components/common/commonStyled';
import { activeHeartRateValueMap, HeartRateValue } from '../../components/ExerciseForm/types';
import Calendar from './components/Calendar';
import * as S from './index.styles';
import { ExerciseActions } from './state/actions';
import { ExerciseContext, ExerciseState } from './state/ExerciseContext';
import toast from 'react-hot-toast';
import { useActionLoader } from '../../hooks/useActionLoader';
import { fetchExerciseSummary } from '../../state/reducers/physicalTherapy';
import { Routes } from '../../api/Routes';
import { NaiveDate, formatDateForInput } from '../../utils/dateUtils';
import UnsavedChangesIcon from '../../components/UnsavedChangesIcon';
import { TenantApi } from '../../api/CoachingAPI';
import { ApiResponse, RequestMethod, useApiRequest } from '../../hooks/useApiRequest';
import { ExercisePlanType, IExercisePlan, IExercisePlanDischargeDatePayload } from '@apps/common-utilities/src/types/exerciseTypes';
import { exercisePlanTypeLabel } from '../../components/ExerciseForm/utils';
import ManageProgramModal from './components/ManageProgramModal';

type IExercisePlanCreatePayload = typeUtils.Modify<ExerciseTypes.IExercisePlan, {
    id: null,
    userId: string,
    publishedAt: null,
    assignedExercises: typeUtils.Modify<ExerciseTypes.IAssignedExercise, {
        id: null,
        exercisePlanId: null,
    }>[]
}>;

const Dialogue = ({ lockExercises, toggleLockExercises }:
    {lockExercises: boolean, toggleLockExercises: () => void}) => {
    const [isLocked, setIsLocked] = useState(lockExercises);

    const updateLocked = () => {
        toggleLockExercises();
        setIsLocked(!isLocked);
    };

    return (
        <S.ConfirmContainer>
            <label htmlFor="hide-previous" style={{ cursor: 'pointer' }}>
                Disable access to previous exercises?
                <input
                  id="hide-previous"
                  type="checkbox"
                  onChange={updateLocked}
                  defaultChecked={isLocked}
                />
            </label>
            {isLocked && <S.LockStateWarning>This will permanently disable previous plan exercises.</S.LockStateWarning>}
        </S.ConfirmContainer>
    );
};

const ExercisePlan = () => {
    const { patientId } = useParams<{ patientId: string }>();
    const { state, dispatch } = useContext(ExerciseContext);
    const [manageProgram, setManageProgram] = useState(false);
    const { patients } = useSelector((store: RootState) => store.coaching);
    const { exerciseSummary } = useSelector((store: RootState) => store.physicalTherapy);
    const { callAction: getExerciseSummary, done, loading } = useActionLoader(fetchExerciseSummary);
    const [heartRateRange, setHeartRateRange] = useState<any>({});
    // use ref ensures we're using the up to date value set in the confirm dialog
    // rather than the stale value in state
    const lockExercises = useRef(false);
    const publish = useRef(false);
    const [saving, setSaving] = useState(false);
    const { callApi: createPlan, done: createPlanDone } = useApiRequest<ExerciseTypes.IExercisePlan>(RequestMethod.POST);
    const { callApi: updatePlan, done: updatePlanDone } = useApiRequest<ExerciseTypes.IExercisePlan>(RequestMethod.PUT);

    const shouldHaveEndDate = state.exercisePlan.exercisePlanType !== ExercisePlanType.NON_PT_MAINTENANCE;
    const endDateFallBack = new NaiveDate(new Date().toISOString());
    let diffInWeeks: number | null = null;
    if (shouldHaveEndDate && state.exercisePlan.endDate) {
        diffInWeeks = differenceInCalendarWeeks(
            new Date(state.exercisePlan.endDate),
            new Date(state.exercisePlan.startDate),
            { weekStartsOn: 1 }
        );
    }

    const { ConfirmationDialog, confirm } = useConfirmDialog({
        message: '',
        buttons: {
            yes: 'Publish',
            no: 'Cancel'
        },
        successType: 'success'
    });
    // force rerender since context is not triggering rerender
    const [forceRerender, setForceRerender] = useState(0);

    const getValidatedStartDate = (startDate: string) => {
        const today = format(new Date(), 'yyyy-MM-dd');
        let startYear = startDate.split('-')[0];
        let startMonth = startDate.split('-')[1];
        let startDay = startDate.split('-')[2];

        // if the year, month or day are invalid set them to the corresponding value of the current date
        // year must not be less than the current year
        // month must be between 1 and 12
        // day must be between 1 and the number of days in the month
        if (Number(startYear) < Number(today.split('-')[0])) {
            [startYear] = today.split('-');
        } else if (Number(startMonth) < 1 || Number(startMonth) > 12) {
            [, startMonth] = today.split('-');
        } else if (Number(startDay) < 1 || Number(startDay) > getDaysInMonth(new Date(Number(startYear), Number(startMonth) - 1))) {
            [, , startDay] = today.split('-');
        }

        // If the start date is in the past set it to the current date
        if (new NaiveDate(`${startYear}-${startMonth}-${startDay}`) < new NaiveDate(today)) {
            return `${today.split('-')[0]}-${today.split('-')[1]}-${today.split('-')[2]}`;
        }
        return `${startYear}-${startMonth}-${startDay}`;
    };

    const getValidatedEndDate = (endDate: string) => {
        const today = format(new Date(), 'yyyy-MM-dd');
        let endYear = endDate.split('-')[0];
        let endMonth = endDate.split('-')[1];
        let endDay = endDate.split('-')[2];

        // if the year, month or day are invalid set them to the corresponding value of the start date
        // year must not be less than the start year
        // month must be between 1 and 12
        // day must be between 1 and the number of days in the month
        if (Number(endYear) < Number(state.exercisePlan.startDate.split('-')[0])) {
            [endYear] = state.exercisePlan.startDate.split('-');
        } else if (Number(endMonth) < 1 || Number(endMonth) > 12) {
            [, endMonth] = state.exercisePlan.startDate.split('-');
        } else if (Number(endDay) < 1 || Number(endDay) > getDaysInMonth(new Date(Number(endYear), Number(endMonth) - 1))) {
            [, , endDay] = state.exercisePlan.startDate.split('-');
        }

        // If the end date is in the past set it to the current date
        if (new NaiveDate(`${endYear}-${endMonth}-${endDay}`) < new NaiveDate(today)) {
            return `${today.split('-')[0]}-${today.split('-')[1]}-${today.split('-')[2]}`;
        }
        return `${endYear}-${endMonth}-${endDay}`;
    };

    const setProgramInfo = (updates) => {
        // check if start date is being updated
        if (Object.keys(updates).includes('startDate')) {
            // don't allow state to update if null date was passed
            if (!updates.startDate) {
                return;
            }
            updates.startDate = getValidatedStartDate(updates.startDate);
        }
        // check if end date is being updated
        if (Object.keys(updates).includes('endDate')) {
            // don't allow state to update if null date was passed
            if (!updates.endDate) {
                return;
            }
            updates.endDate = getValidatedEndDate(updates.endDate);
        }

        dispatch({
            type: ExerciseActions.SET_PROGRAM_INFO,
            payload: { ...state.exercisePlan, ...updates }
        });
        setForceRerender(forceRerender + 1);
    };

    const handlePlanChange = () => {
        dispatch({
            type: ExerciseActions.SET_UNSAVED_CHANGES,
            payload: true,
        });
    };

    const setHeartRateRangeOverride = (value) => {
        if (value === 'none') {
            dispatch({
                type: ExerciseActions.SET_DEFAULT_HEART_RATE_RANGE,
                payload: {
                    minHeartRatePercent: null,
                    maxHeartRatePercent: null
                },
            });
        } else {
            dispatch({
                type: ExerciseActions.SET_DEFAULT_HEART_RATE_RANGE,
                payload: {
                    minHeartRatePercent: activeHeartRateValueMap[value].min,
                    maxHeartRatePercent: activeHeartRateValueMap[value].max
                },
            });
        }
    };

    const getOverrideHeartRateRange = () => {
        const { minHeartRatePercentOverride, maxHeartRatePercentOverride } = state.exercisePlan;
        if (minHeartRatePercentOverride === 40 && maxHeartRatePercentOverride === 80) {
            return HeartRateValue.FORTY_TO_EIGHTY;
        } else if (minHeartRatePercentOverride === 40 && maxHeartRatePercentOverride === 60) {
            return HeartRateValue.FORTY_TO_SIXTY;
        } else if (minHeartRatePercentOverride === 60 && maxHeartRatePercentOverride === 80) {
            return HeartRateValue.SIXTY_TO_EIGHTY;
        }
        return null;
    };

    const updateLocked = () => {
        lockExercises.current = !lockExercises.current;
    };

    const checkForDuplicateExercises = (eState: ExerciseState) => {
        // Check for days with duplicate exercises
        let exercisesOnDay: ExerciseTypes.IAssignedExercise[] = [];
        let duplicateFound: boolean = false;
        const duplicateDay: ExerciseTypes.Days | undefined = Object.values(ExerciseTypes.Days).find((day) => {
            exercisesOnDay = eState.exercisePlan.assignedExercises.filter((e) => e.dayOfWeek === day);
            exercisesOnDay.forEach((exercise) => {
                if (duplicateFound) {
                    return;
                }
                if (exercisesOnDay.filter((e) => e.exerciseId === exercise.exerciseId).length > 1) {
                    duplicateFound = true;
                }
            });
            return duplicateFound;
        });
        return duplicateDay;
    };

    const validateProgramInfo = (eState: ExerciseState): boolean => {
        if (!eState.exercisePlan.userAge) {
            toast.error('Please enter a valid age');
            return false;
        }
        if (!eState.exercisePlan.restingHeartRate) {
            toast.error('Please enter a valid resting heart rate');
            return false;
        }
        if (!eState.exercisePlan.spO2PercentTarget) {
            toast.error('Please enter a valid SPO2 target');
            return false;
        }
        if (shouldHaveEndDate && !eState.exercisePlan.endDate) {
            toast.error('Please enter an end date');
            return false;
        }
        if (!shouldHaveEndDate && eState.exercisePlan.endDate) {
            toast.error('Non-PT maintenance plans should not have an end date');
            return false;
        }
        if ((eState.exercisePlan.exercisePlanType !== ExercisePlanType.NON_PT_MAINTENANCE && eState.exercisePlan.endDate)
            && new Date(eState.exercisePlan.startDate) > new Date(eState.exercisePlan.endDate)) {
            toast.error('Program end date must be after start date');
            return false;
        }
        const duplicateDay = checkForDuplicateExercises(eState);
        if (duplicateDay) {
            const dayString = `${duplicateDay}`[0].toUpperCase() + `${duplicateDay}`.slice(1).toLowerCase();
            toast.error(`Duplicate exercises on ${dayString}`);
            return false;
        }
        return true;
    };

    const dischargePlan = async (id: number) => {
        const dischargeDatePayload: IExercisePlanDischargeDatePayload = {
            dischargeDate: new Date().toISOString()
        };
        const dischargeRes: IExercisePlanDischargeDatePayload = await TenantApi.patch(`/${Routes.coaches}/${Routes.exercisePlans}/${id}/discharge-date`, dischargeDatePayload);
        if (dischargeRes) {
            dispatch({ type: ExerciseActions.SET_DISCHARGE_ID_ON_CREATE, payload: null });
            dispatch({ type: ExerciseActions.SET_EXERCISE_PLAN, payload: { ...state.exercisePlan, dischargeDate: dischargeRes.dischargeDate, complete: true } });
            toast.success('Patient discharged successfully');
            return dischargeRes;
        } else {
            toast.error('Failed to discharge patient and update plan');
            return null;
        }
    };

    const savePlan = async (exercisePlan: IExercisePlan) => {
        setSaving(true);
        const payload: IExercisePlanCreatePayload = {
            ...exercisePlan,
            userId: patientId!,
            id: null,
            publishedAt: null,
            published: publish.current,
            startDate: new NaiveDate(exercisePlan.startDate).setTimeToMidnight().toISOString(),
            endDate: exercisePlan.endDate ? new NaiveDate(exercisePlan.endDate).setTimeToMidnight().toISOString() : null,
            blockPreviousPlans: lockExercises.current,
            assignedExercises: exercisePlan.assignedExercises.map((a) => ({
                ...a,
                id: null,
                exercisePlanId: null,
                exerciseVariable: {
                    ...a.exerciseVariable,
                    id: null,
                }
            }))
        };

        if (!state.exercisePlan.published && state.exercisePlan.id) {
            // editing a draft
            updatePlan(`/${Routes.coaches}/${Routes.exercisePlans}/${state.exercisePlan.id}`, payload).then(({ response, error }: ApiResponse<ExerciseTypes.IExercisePlan>) => {
                if (!!error.error || !!error.message) {
                    toast.error(`Failed to save plan. ${error.error || error.message}`, { duration: 5000 });
                } else if (response.data) {
                    dispatch({ type: ExerciseActions.SET_EXERCISE_PLAN, payload: response.data });
                    dispatch({ type: ExerciseActions.RESET_UNSAVED_CHANGES, payload: null });
                    if (publish.current) {
                        toast.success('Exercise plan published');
                    } else {
                        toast.success('Exercise plan saved');
                    }
                }
            }).catch(err => {
                toast.error('Failed to save plan. Updates were not published.');
            });
        } else {
            if (state.dischargeIdOnCreate != null) {
                const res = await dischargePlan(state.dischargeIdOnCreate);
                if (!res) {
                    return;
                }
            }
            // creating a new plan if editing a published plan or creating a new plan
            createPlan(`/${Routes.coaches}/${Routes.exercisePlans}`, payload).then(({ response, error }: ApiResponse<ExerciseTypes.IExercisePlan>) => {
                if (!!error.error || !!error.message) {
                    toast.error(`Failed to save plan. ${error.error || error.message}`, { duration: 5000 });
                } else if (response.data) {
                    dispatch({ type: ExerciseActions.SET_EXERCISE_PLAN, payload: response.data });
                    dispatch({ type: ExerciseActions.RESET_UNSAVED_CHANGES, payload: null });
                    if (publish.current) {
                        toast.success('Exercise plan published');
                    } else {
                        toast.success('Exercise plan draft saved');
                    }
                }
            }).catch(err => {
                toast.error('Failed to save plan. Plan was not published');
            });
        }
    };

    const confirmPlan = async () => {
        const isValid = validateProgramInfo(state);
        if (!isValid) {
            return;
        }
        const confirmed = await confirm();
        if (confirmed) {
            savePlan(state.exercisePlan);
        }
    };

    const checkDraftBeforeSaving = () => {
        const duplicateDay = checkForDuplicateExercises(state);
        if (duplicateDay) {
            const dayString = `${duplicateDay}`[0].toUpperCase() + `${duplicateDay}`.slice(1).toLowerCase();
            toast.error(`Duplicate exercises on ${dayString}`);
            return;
        }
        savePlan(state.exercisePlan);
    };

    const initializeExercisePlan = () => {
        const patient = patients.find((p) => p.id === patientId);
        if (!state.exercisePlan.userId && patient) {
            dispatch({
                type: ExerciseActions.SET_PROGRAM_INFO,
                payload: {
                    ...state.exercisePlan,
                    userId: patientId,
                    userAge: differenceInYears(new Date(), new Date(patient.dateOfBirth))
                }
            });
        }
        if (
            exerciseSummary
            && state.exercisePlan.id === null
            && state.exercisePlan.assignedExercises.length === 0
            && exerciseSummary.userId === patientId
        ) {
            TenantApi.get(`/${Routes.coaches}/${Routes.exercisePlans}/${exerciseSummary.exercisePlanId}`).then((plan) => {
                dispatch({ type: ExerciseActions.SET_EXERCISE_PLAN, payload: plan });
                setForceRerender(forceRerender + 1);
            });
        }
    };

    useEffect(() => {
        setHeartRateRange({
            '40%': Utils.KarvonenFormula(
                state.exercisePlan.userAge,
                state.exercisePlan.restingHeartRate,
                state.exercisePlan.onBetaBlockers,
                40
            ),
            '60%': Utils.KarvonenFormula(
                state.exercisePlan.userAge,
                state.exercisePlan.restingHeartRate,
                state.exercisePlan.onBetaBlockers,
                60
            ),
            '80%': Utils.KarvonenFormula(
                state.exercisePlan.userAge,
                state.exercisePlan.restingHeartRate,
                state.exercisePlan.onBetaBlockers,
                80
            ),
            max: Utils.KarvonenFormula(
                state.exercisePlan.userAge,
                state.exercisePlan.restingHeartRate,
                state.exercisePlan.onBetaBlockers,
                100
            ),
        });
        setForceRerender(forceRerender + 1);
    }, [state.exercisePlan.userAge, state.exercisePlan.restingHeartRate, state.exercisePlan.onBetaBlockers]);

    // initialize exercise plan
    useEffect(() => {
        if (!done && !loading) {
            getExerciseSummary(patientId);
        }
    }, [patientId, exerciseSummary, createPlanDone, updatePlanDone]);

    useEffect(() => {
        if (exerciseSummary?.exercisePlanId) {
            initializeExercisePlan();
        }
    }, [patientId, exerciseSummary]);

    useEffect(() => {
        if (saving) {
            setTimeout(() => {
                setSaving(false);
            }, 3000);
        }
    }, [saving]);

    return (
        <S.ExercisePlanContainer>
            { state.hasUnsavedChanges && (
                <UnsavedChangesIcon text="Unsaved changes" />
            )}
            <ConfirmationDialog>
                <Dialogue lockExercises={lockExercises.current} toggleLockExercises={updateLocked} />
            </ConfirmationDialog>
            <ManageProgramModal
              onDischarge={async () => {
                if (state.exercisePlan.id) {
                    dischargePlan(state.exercisePlan.id);
                }
              }}
              showModal={manageProgram}
              dismissModal={() => setManageProgram(false)}
              createNonPtMaintenancePlan={(exercisePlan: IExercisePlan) => {
                publish.current = true;
                savePlan(exercisePlan);
            }}
            />
            <S.HeaderContainer>
                <S.HeaderSection>
                    <h2>Exercise Plan</h2>
                    {state.exercisePlan.published && state.exercisePlan.paused && (
                        <ProgramStatusIndicator status="danger">
                            Program Disabled
                        </ProgramStatusIndicator>
                    )}
                    {!state.exercisePlan.published && (
                        <ProgramStatusIndicator status="info">
                            Program Draft
                        </ProgramStatusIndicator>
                    )}
                </S.HeaderSection>
                <S.HeaderSection>
                    <S.ExerciseButton
                      buttonType="secondary"
                      onClick={() => setManageProgram(true)}
                      disabled={state.exercisePlan.id === null}
                      data-toolTip={state.exercisePlan.id === null ? 'Create an exercise plan before managing' : null}
                    >
                        Manage Program
                    </S.ExerciseButton>
                    <S.ExerciseButton
                      size="medium"
                      buttonType="secondary"
                      onClick={() => { publish.current = false; checkDraftBeforeSaving(); }}
                      disabled={state.exercisePlan.complete || saving}
                    >
                        Save as Draft
                    </S.ExerciseButton>
                    {state.exercisePlan.complete && (
                        <ProgramStatusIndicator status="danger">
                            Patient Discharged
                        </ProgramStatusIndicator>
                    )}
                    {!state.exercisePlan.complete && (
                        <S.ExerciseButton
                          size="medium"
                          buttonStyle="success"
                          onClick={() => { publish.current = true; confirmPlan(); }}
                          disabled={saving}
                        >
                            Publish Program
                        </S.ExerciseButton>
                    )}

                </S.HeaderSection>
            </S.HeaderContainer>
            <S.ProgramDetailsContainer>
                <S.PlanTypeContainer status={state.exercisePlan.exercisePlanType === ExercisePlanType.NON_PT_MAINTENANCE ? 'danger' : 'success'}>
                    { exercisePlanTypeLabel(state.exercisePlan.exercisePlanType) }
                </S.PlanTypeContainer>

            </S.ProgramDetailsContainer>
            <S.ProgramDetailsContainer>
                <S.ProgramDetailsColumn>
                    {shouldHaveEndDate && <S.ExerciseTitle>Program Length: {diffInWeeks} weeks </S.ExerciseTitle>}
                    <S.ExerciseTitle>Start: {format(new NaiveDate(state.exercisePlan.startDate), 'MMMM do yyyy')}</S.ExerciseTitle>
                    <S.ExerciseDateSelect
                      value={formatDateForInput(new NaiveDate(state.exercisePlan.startDate))}
                      disabled={exerciseSummary && !exerciseSummary.complete && !state.dischargeIdOnCreate && isPast(new Date(exerciseSummary?.publishedAt))}
                      inputSize={Inputs.InputSize.medium}
                      type="date"
                      min={!state.exercisePlan.id && formatDateForInput(new Date())}
                      onChange={(e) => {
                        setProgramInfo({ startDate: e.target.value });
                        handlePlanChange();
                        }}
                    />
                    {shouldHaveEndDate && (
                    <>
                        <S.ExerciseTitle>End: {state.exercisePlan.endDate ? format(new NaiveDate(state.exercisePlan.endDate), 'MMMM do yyyy') : format(endDateFallBack, 'MMMM do yyyy')}</S.ExerciseTitle>
                        <S.ExerciseDateSelect
                          value={state.exercisePlan.endDate ? formatDateForInput(new NaiveDate(state.exercisePlan.endDate)) : formatDateForInput(endDateFallBack)}
                          inputSize={Inputs.InputSize.medium}
                          type="date"
                          min={formatDateForInput(new Date())}
                          onChange={(e) => {
                        setProgramInfo({ endDate: e.target.value });
                        handlePlanChange();
                    }}
                        />
                    </>
)}
                </S.ProgramDetailsColumn>
                <S.ProgramDetailsColumn>
                    <S.ExerciseTitle>HR Range (bpm)</S.ExerciseTitle>
                    <S.HeartRateRange>
                        <S.HeartRateRangeContainer>
                            <div>
                                <S.ExerciseText>Age</S.ExerciseText>
                                <Inputs.Input
                                  inputSize={Inputs.InputSize.extraSmall}
                                  type="number"
                                  value={state.exercisePlan.userAge}
                                  min="0"
                                  onChange={(e: any) => {
                                    setProgramInfo({ userAge: parseInt(e.target.value, 10) });
                                    handlePlanChange();
                                    }}
                                />
                            </div>
                            <div>
                                <S.ExerciseText>Resting HR</S.ExerciseText>
                                <Inputs.Input
                                  inputSize={Inputs.InputSize.extraSmall}
                                  type="number"
                                  value={state.exercisePlan.restingHeartRate}
                                  min="0"
                                  onChange={(e: any) => {
                                    setProgramInfo({ restingHeartRate: parseInt(e.target.value, 10) });
                                    handlePlanChange();
                                    }}
                                />
                            </div>
                            <div>
                                <S.ExerciseText>Beta Blockers?</S.ExerciseText>
                                <div>
                                    <input
                                      type="radio"
                                      id="beta-blockers-yes"
                                      value="yes"
                                      checked={state.exercisePlan.onBetaBlockers}
                                      onChange={() => {
                                        setProgramInfo({ onBetaBlockers: true });
                                        handlePlanChange();
                                        }}
                                    />
                                    <Inputs.Label htmlFor="beta-blockers-yes">Yes</Inputs.Label>
                                </div>
                                <div>
                                    <input
                                      type="radio"
                                      id="beta-blockers-no"
                                      value="no"
                                      checked={!state.exercisePlan.onBetaBlockers}
                                      onChange={() => {
                                        setProgramInfo({ onBetaBlockers: false });
                                        handlePlanChange();
                                        }}
                                    />
                                    <Inputs.Label htmlFor="beta-blockers-no">No</Inputs.Label>
                                </div>
                            </div>
                        </S.HeartRateRangeContainer>
                        <S.HeartRateRangeContainer>
                            <div>
                                <S.ExerciseText>Max</S.ExerciseText>
                                <Inputs.Input disabled inputSize={Inputs.InputSize.extraSmall} type="number" value={heartRateRange.max || 0} min="0" />
                            </div>
                            <div>
                                <S.ExerciseText>40%</S.ExerciseText>
                                <Inputs.Input disabled inputSize={Inputs.InputSize.extraSmall} type="number" value={heartRateRange['40%'] || 0} min="0" />
                            </div>
                            <div>
                                <S.ExerciseText>60%</S.ExerciseText>
                                <Inputs.Input disabled inputSize={Inputs.InputSize.extraSmall} type="number" value={heartRateRange['60%'] || 0} min="0" />
                            </div>
                            <div>
                                <S.ExerciseText>80%</S.ExerciseText>
                                <Inputs.Input disabled inputSize={Inputs.InputSize.extraSmall} type="number" value={heartRateRange['80%'] || 0} min="0" />
                            </div>
                        </S.HeartRateRangeContainer>
                    </S.HeartRateRange>
                </S.ProgramDetailsColumn>
                <S.ProgramDetailsColumn>
                    <S.ExerciseTitle>Default Overrides</S.ExerciseTitle>
                    <p>For all relevant assigned exercises</p>
                    <S.ExerciseTitle>HR Range</S.ExerciseTitle>
                    <Inputs.Select
                      size="medium"
                      style={{ marginLeft: 0 }}
                      onChange={(e) => {
                        setHeartRateRangeOverride(e.target.value);
                        handlePlanChange();
                    }}
                      value={getOverrideHeartRateRange()}
                    >
                        <option value="none">No override</option>
                        <option value={HeartRateValue.FORTY_TO_SIXTY}>40-60%</option>
                        <option value={HeartRateValue.FORTY_TO_EIGHTY}>40-80%</option>
                        <option value={HeartRateValue.SIXTY_TO_EIGHTY}>60-80%</option>
                    </Inputs.Select>
                    <S.ExerciseTitle>SPO2 Above (%)</S.ExerciseTitle>
                    <Inputs.Input
                      onChange={(e) => {
                        setProgramInfo({ spO2PercentTarget: parseInt(e.target.value, 10) });
                        handlePlanChange();
                    }}
                      value={state.exercisePlan.spO2PercentTarget}
                      type="number"
                      inputSize={Inputs.InputSize.extraSmall}
                      max="100"
                      min="88"
                    />
                </S.ProgramDetailsColumn>
            </S.ProgramDetailsContainer>
            <S.CalendarContainer>
                <Calendar />
            </S.CalendarContainer>
        </S.ExercisePlanContainer>
    );
};

export default ExercisePlan;
