import { Modal, Inputs } from '@apps/common-ui';
import React, { useEffect, useState } from 'react';
import * as S from './index.styles';
import { LearningTypes } from '@apps/common-utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faX } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../state/store';

type Props = {
    showModal: boolean;
    dismissModal: () => void;
    modulesAlreadyInCourse: LearningTypes.IModule[];
    addModules: (modules: LearningTypes.IModule[]) => void;
}

const AddModuleModal = ({ showModal, dismissModal, modulesAlreadyInCourse, addModules }: Props) => {
    const { modules } = useSelector((state: RootState) => state.learning);
    const [canAdd, setCanAdd] = useState(false);
    const [searchString, setSearchString] = useState('');
    const [modulesForList, setModulesForList] = useState<LearningTypes.IModule[]>([]);
    const [selectedModules, setSelectedModules] = useState<LearningTypes.IModule[]>([]);

    const getModulesForList = () => {
        return modules.filter((module) => {
            return !modulesAlreadyInCourse.some((moduleInCourse) => moduleInCourse.id === module.id);
        });
    };

    // if the module is selected already then remove it, otherwise add it to the selected modules
    const updateSelectedModules = (module: LearningTypes.IModule) => {
        if (selectedModules.some((selectedModule) => selectedModule.id === module.id)) {
            setSelectedModules(selectedModules.filter((selectedModule) => selectedModule.id !== module.id));
        } else {
            setSelectedModules([...selectedModules, module]);
        }
    };

    useEffect(() => {
        setModulesForList(getModulesForList());
    }, [modulesAlreadyInCourse]);

    useEffect(() => {
        setCanAdd(selectedModules.length > 0);
    }, [selectedModules]);

    useEffect(() => {
        if (searchString) {
            setModulesForList(getModulesForList().filter((module) => module.title.toLowerCase().includes(searchString) || module.internalTitle.toLowerCase().includes(searchString)));
        } else {
            setModulesForList(getModulesForList());
        }
    }, [searchString]);

    return (
        <Modal
          showModal={showModal}
          dismissModal={dismissModal}
          showTopBar={false}
          closeOnOutsideClick={false}
          overflow="hidden"
        >
            <S.TitleBar>
                <S.Title>Add Module(s)</S.Title>
                <S.CloseButton onClick={dismissModal}><FontAwesomeIcon icon={faX as IconProp} /> Close</S.CloseButton>
            </S.TitleBar>
            <S.ModalContent>
                <S.SearchBarContainer>
                    <S.SearchBar
                      placeholder="Search"
                      value={searchString}
                      onChange={(e) => setSearchString(e.target.value.toLowerCase())}
                    />
                    <FontAwesomeIcon icon={faMagnifyingGlass as IconProp} />
                </S.SearchBarContainer>
                <S.ModuleList>
                    {modulesForList.map((module) => (
                        <S.ModuleRow key={module.id}>
                            <Inputs.Checkbox
                              aria-label={`Select${module.id}`}
                              id={module.id.toString()}
                              defaultChecked={selectedModules.some((selectedModule) => selectedModule.id === module.id)}
                              onChange={(e) => updateSelectedModules(module)}
                            />
                            <S.ModuleImage src={module.thumbnailUrl} />
                            <S.ModuleDetailsContainer>
                                <S.ModuleInternalTitle>{module.internalTitle}</S.ModuleInternalTitle>
                                <S.ModuleExternalTitle>{module.title}</S.ModuleExternalTitle>
                            </S.ModuleDetailsContainer>
                        </S.ModuleRow>
                    ))}
                </S.ModuleList>
                <S.AddModuleButton
                  canAdd={canAdd}
                  onClick={() => {
                        if (canAdd) {
                            addModules(selectedModules);
                            setSelectedModules([]);
                            dismissModal();
                        }
                    }}
                >
                    Add Module
                </S.AddModuleButton>
            </S.ModalContent>
        </Modal>
    );
};
export default AddModuleModal;
