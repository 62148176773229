import { Buttons, Inputs } from '@apps/common-ui';
import React, { useContext, useEffect, useState } from 'react';
import { ActionPlanContext } from '../..';
import { IShortnessOfBreathCause, PhlegmColor } from '../../../../types/models';
import { ReducerActionType } from '../../reducer';
import { ZoneSectionContainer, ZoneSectionHeader } from '../common';
import * as S from './index.styles';

const CopdSymptoms = () => {
    const { actionPlan, updateActionPlan } = useContext(ActionPlanContext);
    const [shortnessOfBreathCauses, setShortnessOfBreathCauses] = useState<IShortnessOfBreathCause[]>([]);

    useEffect(() => {
        if (actionPlan && actionPlan.copdInfo) {
            setShortnessOfBreathCauses(actionPlan.copdInfo.shortnessOfBreathCauses);
        }
    }, [actionPlan]);

    const onUpdatePhlegmColor = (phlegmColor: string) => {
        updateActionPlan({
            type: ReducerActionType.UPDATE_COPD_INFO,
            payload: {
                shortnessOfBreathCauses,
                phlegmColour: phlegmColor as PhlegmColor
            }
        });
    };

    const onUpdateShortnessOfBreathCause = (cause: string, index: number) => {
        const updatedCauses = [...shortnessOfBreathCauses];
        updatedCauses[index].cause = cause;
        setShortnessOfBreathCauses(updatedCauses);
        updateActionPlan({
            type: ReducerActionType.UPDATE_COPD_INFO,
            payload: {
                ...actionPlan.copdInfo,
                shortnessOfBreathCauses: updatedCauses
            }
        });
    };

    const onDeleteShortnessOfBreathCause = (index: number) => {
        const newCauses = [...shortnessOfBreathCauses];
        newCauses.splice(index, 1);
        setShortnessOfBreathCauses(newCauses);
        updateActionPlan({
            type: ReducerActionType.UPDATE_COPD_INFO,
            payload: {
                ...actionPlan.copdInfo,
                shortnessOfBreathCauses: newCauses
            }
        });
    };

    const onAddShortnessOfBreathCauses = () => {
        setShortnessOfBreathCauses([
            ...shortnessOfBreathCauses,
            { cause: '' }
        ]);
    };

    return (
        <ZoneSectionContainer>
            <ZoneSectionHeader>Symptoms</ZoneSectionHeader>
            <S.PhlegmSelectContainer>
                <Inputs.Label style={{ marginLeft: 0 }}>Usual sputum (phlegm) color is:
                    <Inputs.Select
                      onChange={e => onUpdatePhlegmColor(e.target.value)}
                      defaultValue={actionPlan?.copdInfo?.phlegmColour}
                      disabled={!actionPlan.isActive}
                    >
                        <option value={PhlegmColor.CLEAR}>Clear</option>
                        <option value={PhlegmColor.WHITE}>White</option>
                        <option value={PhlegmColor.YELLOW_WHITE}>Yellow-white</option>
                        <option value={PhlegmColor.YELLOW_GREEN}>Yellow-green</option>
                        <option value={PhlegmColor.GREEN}>Green</option>
                    </Inputs.Select>
                </Inputs.Label>
            </S.PhlegmSelectContainer>
            <S.ShortnessOfBreathCausesContainer>
                <Inputs.Label>
                    I feel short of breath when:
                </Inputs.Label>
                {shortnessOfBreathCauses.map(({ cause }, index) => (
                    <S.InputContainer key={index}>
                        <Inputs.Input
                          key={index}
                          value={cause}
                          onChange={e => onUpdateShortnessOfBreathCause(e.target.value, index)}
                          placeholder="Enter cause"
                          disabled={!actionPlan.isActive}
                        />
                        <Buttons.Button
                          size="small"
                          buttonType="secondary"
                          onClick={e => onDeleteShortnessOfBreathCause(index)}
                          buttonStyle="danger"
                          title="Delete"
                          disabled={!actionPlan.isActive}
                        >
                            Remove
                        </Buttons.Button>
                    </S.InputContainer>
                ))}
                <Buttons.Button
                  buttonType="secondary"
                  size="medium"
                  onClick={onAddShortnessOfBreathCauses}
                  disabled={!actionPlan.isActive}
                >+ Add Item
                </Buttons.Button>
            </S.ShortnessOfBreathCausesContainer>
        </ZoneSectionContainer>
    );
};

export default CopdSymptoms;
