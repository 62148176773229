import React, { useEffect, useState } from 'react';
import { LearningTypes } from '@apps/common-utilities';
import { Modal } from '@apps/common-ui';
import * as S from './index.styles';
import { useForm } from 'react-hook-form';
import { ApiResponse, RequestMethod, useApiRequest } from '../../../../hooks/useApiRequest';
import { IDuplicateSubModule, IModule } from '@apps/common-utilities/src/types/learningTypes';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../state/store';
import { useActionLoader } from '../../../../hooks/useActionLoader';
import { fetchModules } from '../../../../state/reducers/learning';

type Props = {
    showModal: boolean;
    dismissModal: () => void;
    refreshModule: () => void;
    moduleId: number;
    submodule?: LearningTypes.ISubModuleSummary;
};

const DuplicateModal = ({ showModal, dismissModal, refreshModule, moduleId, submodule } : Props) => {
    const { register, watch, setValue, formState: { errors }, handleSubmit, setError } = useForm();
    const [modulesToDisplay, setModulesToDisplay] = useState<LearningTypes.IModule[]>([]);
    const [moduleIdToDuplicateTo, setModuleIdToDuplicateTo] = useState<number | null>(null);
    const modules = useSelector((state: RootState) => state.learning.modules);
    const { callApi: duplicateLesson } = useApiRequest<LearningTypes.ILesson>(RequestMethod.POST);
    const { callApi: duplicateQuiz } = useApiRequest<LearningTypes.IQuiz>(RequestMethod.POST);

    const { callAction: getModules, done: modulesFetched } = useActionLoader(fetchModules);

    const getSubmoduleString = () => {
        switch (submodule?.subModuleType) {
            case LearningTypes.SubModuleType.LESSON:
                return 'Lesson';
            case LearningTypes.SubModuleType.QUIZ:
                return 'Quiz';
            default:
                return '';
        }
    };

    const duplicateSubmodule = async () => {
        if (!!submodule && moduleIdToDuplicateTo && watch('submoduleName')) {
            const body: IDuplicateSubModule = {
                moduleId: moduleIdToDuplicateTo,
                title: watch('submoduleName')
            };

            if (submodule.subModuleType === LearningTypes.SubModuleType.LESSON) {
                duplicateLesson(`/coaches/education/lessons/${submodule.id}/duplicate`, body).then(({ response, error }: ApiResponse<LearningTypes.ILesson>) => {
                    if (error.hasError) {
                        toast.error(`Failed to duplicate lesson. ${error.error || error.message}`, { duration: 5000 });
                    } else if (response.data) {
                        toast.success('Lesson duplicated successfully', { duration: 5000 });
                        if (moduleIdToDuplicateTo === moduleId) {
                            refreshModule();
                        }
                    }
                    dismissModal();
                });
            } else if (submodule.subModuleType === LearningTypes.SubModuleType.QUIZ) {
                duplicateQuiz(`/coaches/education/quizzes/${submodule.id}/duplicate`, body).then(({ response, error }: ApiResponse<LearningTypes.IQuiz>) => {
                    if (error.hasError) {
                        toast.error(`Failed to duplicate quiz. ${error.error || error.message}`, { duration: 5000 });
                    } else if (response.data) {
                        toast.success('Quiz duplicated successfully', { duration: 5000 });
                        if (moduleIdToDuplicateTo === moduleId) {
                            refreshModule();
                        }
                    }
                    dismissModal();
                });
            }
        }
    };

    useEffect(() => {
        if (submodule) {
            setValue('submoduleName', `${submodule.title}`);
        }
        getModules();
    }, [submodule]);

    useEffect(() => {
        if (modulesFetched) {
            setModulesToDisplay(modules);
        }
    }, [modulesFetched]);

    if (!submodule) {
        return null;
    }

    return (
        <Modal
          showModal={showModal}
          dismissModal={dismissModal}
          title={`Duplicate ${getSubmoduleString()}`}
          closeLabel="Close"
        >
            <S.ModalContent>
                <S.ModuleForm onSubmit={handleSubmit(duplicateSubmodule)}>
                    <S.InputLabel>{submodule.subModuleType === LearningTypes.SubModuleType.LESSON ? 'Lesson' : 'Quiz'} Name</S.InputLabel>
                    <S.Input
                      {...register('submoduleName', { required: true })}
                    />
                    <S.InputLabel>Copy Lesson To:</S.InputLabel>
                    <S.Dropdown
                      onChange={(e) => setModuleIdToDuplicateTo(Number(e.target.value))}
                    >
                        <option value="">Select Module</option>
                        {modulesToDisplay.map((module) => (
                            <option key={module.id} value={module.id}>{module.title}</option>
                        ))}
                    </S.Dropdown>
                    <S.ButtonContainer>
                        <S.DuplicateButton
                          type="submit"
                        >
                            Duplicate
                        </S.DuplicateButton>
                        <S.DiscardButton
                          onClick={dismissModal}
                        >
                            Discard
                        </S.DiscardButton>
                    </S.ButtonContainer>
                </S.ModuleForm>
            </S.ModalContent>
        </Modal>
    );
};
export default DuplicateModal;
