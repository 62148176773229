import { ExerciseTypes } from '@apps/common-utilities';
import React from 'react';
import { useNavigate } from 'react-router';
import { useActionLoader } from '../../hooks/useActionLoader';
import { createExercise } from '../../state/reducers/physicalTherapy';
import ExerciseForm from '../../components/ExerciseForm';
import * as S from './index.styles';

const CreateExercise = () => {
    const { callAction: saveExercise, done, error } = useActionLoader(createExercise, {
        successMessage: 'Exercise created successfully',
        errorMessage: 'Failed to create exercise'
    });
    const navigate = useNavigate();
    const onSubmit = (data: ExerciseTypes.IExercise) => {
        saveExercise(data);
    };

    if (done && !error) {
        navigate(-1);
    }

    return (
        <S.Container>
            <h1>Add Exercise</h1>
            <ExerciseForm
              onSubmit={onSubmit}
              editing={false}
            />
        </S.Container>
    );
};

export default CreateExercise;
