import { Inputs } from '@apps/common-ui';
import { Labels, ExerciseTypes } from '@apps/common-utilities';
import React from 'react';
import * as S from '../index.styles';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

interface IFormData {
  sets: number;
  amount: number;
  unit: string;
  holdSeconds?: number;
  inhaleResistance?: number | null;
  exhaleResistance?: number | null;
  reps: number;
  interval?: number;
  holds?: number;
  intervalReps?: number;
  holdReps?: number;
  breathSets?: number;
  breathCycles?: number;
}

const EditExerciseFormat = ({
    exerciseType,
    sets,
    amount,
    unit,
    holdSeconds,
    allowTime,
    allowDistance,
    allowReps,
    inhaleResistance,
    exhaleResistance,
    setExerciseVariable,
}: {
    exerciseType: ExerciseTypes.ExerciseType;
    sets: number;
    amount: number;
    unit: string;
    holdSeconds: number;
    allowTime: boolean;
    allowDistance: boolean;
    allowReps: boolean;
    inhaleResistance: number | null;
    exhaleResistance: number | null;
    setExerciseVariable: (value:
        {
            sets?: number,
            amount?: number,
            unit?: string,
            holdSeconds?: number,
            rmtInhaleResistance?: number,
            rmtExhaleResistance?: number
        }) => void;
}) => {
    const schema = yup.object({
        sets: yup.number().typeError('Please enter a value for sets').required('Please enter a value for sets')
            .min(1, 'Sets must be greater than 0'),
        reps: yup.number().typeError('Please enter a value for reps').required('Please enter a value for reps')
            .min(1, 'Reps must be greater than 0'),
        interval: yup.number().typeError('Please enter a value for intervals').required('Please enter a value for intervals')
            .min(1, 'Intervals must be greater than 0'),
        intervalReps: yup.number().typeError('Please enter a value for reps').required('Please enter a value for reps')
            .min(1, 'Reps must be greater than 0'),
        holds: yup.number().typeError('Please enter a value for # of holds').required('Please enter a value for # of holds')
            .min(1, 'Holds must be greater than 0'),
        holdReps: yup.number().typeError('Please enter a value for reps').required('Please enter a value for reps')
            .min(1, 'Reps must be greater than 0'),
        holdSeconds: yup.number().typeError('Please enter a value for hold time').required('Please enter a value for hold time')
            .min(1, 'Hold time must be greater than 0'),
        breathSets: yup.number().typeError('Please enter a value for sets').required('Please enter a value for sets')
            .min(1, 'Sets must be greater than 0'),
        breathCycles: yup.number().typeError('Please enter a value for breath cycles').required('Please enter a value for breath cycles')
            .min(1, 'Breath cycles must be greater than 0'),
        inhaleResistance: yup.number().typeError('Please enter a value for inhale resistance').required('Please enter a value for inhale resistance')
            .min(1, 'Inhale resistance must be between 1 and 6')
            .max(6, 'Inhale resistance must be between 1 and 6'),
        exhaleResistance: yup.number().typeError('Please enter a value for exhale resistance').required('Please enter a value for exhale resistance')
            .min(1, 'Exhale resistance must be between 1 and 5')
            .max(5, 'Exhale resistance must be between 1 and 5'),
    });

    const { register, formState: { errors } } = useForm({
        values: {
            sets,
            amount,
            unit,
            holdSeconds,
            inhaleResistance,
            exhaleResistance
        } as IFormData,
        resolver: yupResolver(schema),
        mode: 'onBlur',
    });
    return (
        <S.Section>
            <S.InputContainer>
                <h4>Format - {Labels.exerciseType(exerciseType)}</h4>
                <S.InputContainer>
                    {exerciseType === ExerciseTypes.ExerciseType.SETS_AND_REPS && (
                    <S.InputGroup>
                        <Inputs.StyledInputContainer>
                            <Inputs.Label>Sets</Inputs.Label>
                            <Inputs.Input
                              placeholder="Sets"
                              size="small"
                              type="number"
                              min={1}
                              {...register('sets', {
                                value: sets,
                                onChange: (e) => setExerciseVariable({ sets: e.target.value })
                              })}
                            />
                        </Inputs.StyledInputContainer>
                        <Inputs.StyledInputContainer>
                            <Inputs.Label>Reps</Inputs.Label>
                            <Inputs.Input
                              placeholder="Reps"
                              size="small"
                              type="number"
                              min={1}
                              {...register('reps', {
                                value: amount,
                                onChange: (e) => setExerciseVariable({ amount: e.target.value })
                              })}
                            />
                        </Inputs.StyledInputContainer>
                    </S.InputGroup>
                )}
                    {exerciseType === ExerciseTypes.ExerciseType.INTERVALS && (
                    <S.InputGroup style={{ flexDirection: 'column' }}>
                        <S.Section>
                            <Inputs.StyledInputContainer>
                                <Inputs.Label>Interval</Inputs.Label>
                                <Inputs.Input
                                  placeholder="Interval"
                                  size="small"
                                  type="number"
                                  min={1}
                                  {...register('interval', {
                                    value: sets,
                                    onChange: (e) => setExerciseVariable({ sets: e.target.value })
                                  })}
                                />
                            </Inputs.StyledInputContainer>
                            <Inputs.StyledInputContainer>
                                <Inputs.Label>Reps</Inputs.Label>
                                <Inputs.Input
                                  placeholder="Reps"
                                  size="small"
                                  type="number"
                                  min={1}
                                  {...register('intervalReps', {
                                    value: amount,
                                    onChange: (e) => setExerciseVariable({ amount: e.target.value })
                                  })}
                                />
                            </Inputs.StyledInputContainer>
                            <Inputs.StyledInputContainer>
                                <Inputs.Label>Unit</Inputs.Label>
                                <Inputs.Select
                                  onChange={(e) => setExerciseVariable({ unit: e.target.value })}
                                  placeholder="Unit"
                                  value={unit}
                                >
                                    {allowTime && (
                                    <>
                                        <option key="seconds" value={ExerciseTypes.ExerciseIntervalUnit.SECONDS}>Seconds</option>
                                        <option key="minutes" value={ExerciseTypes.ExerciseIntervalUnit.MINUTES}>Minutes</option>
                                    </>
                                )}
                                    {allowDistance && (
                                    <>
                                        <option key="feet" value={ExerciseTypes.ExerciseIntervalUnit.FEET}>Feet</option>
                                        <option key="miles" value={ExerciseTypes.ExerciseIntervalUnit.MILES}>Miles</option>
                                        <option key="meters" value={ExerciseTypes.ExerciseIntervalUnit.METERS}>Meters</option>
                                        <option key="kilometers" value={ExerciseTypes.ExerciseIntervalUnit.KILOMETERS}>Kilometers</option>
                                    </>
                                )}
                                    {allowReps && (
                                    <>
                                        <option key="reps" value={ExerciseTypes.ExerciseIntervalUnit.REPS}>Reps</option>
                                        <option key="steps" value={ExerciseTypes.ExerciseIntervalUnit.STEPS}>Steps</option>
                                    </>
                                )}

                                </Inputs.Select>
                            </Inputs.StyledInputContainer>
                        </S.Section>
                        <p>Displayed as: <b>{amount} intervals of {sets} {unit.toLowerCase()}</b></p>
                    </S.InputGroup>
                )}
                    {exerciseType === ExerciseTypes.ExerciseType.HOLD && (
                    <S.InputGroup>
                        <Inputs.StyledInputContainer>
                            <Inputs.Label># of holds</Inputs.Label>
                            <Inputs.Input
                              placeholder="# of holds"
                              size="small"
                              type="number"
                              min={1}
                              {...register('holds', {
                                value: sets,
                                onChange: (e) => setExerciseVariable({ sets: e.target.value })
                              })}
                            />
                        </Inputs.StyledInputContainer>
                        <Inputs.StyledInputContainer>
                            <Inputs.Label>Reps</Inputs.Label>
                            <Inputs.Input
                              placeholder="Reps"
                              size="small"
                              type="number"
                              min={1}
                              {...register('holdReps', {
                                value: amount,
                                onChange: (e) => setExerciseVariable({ amount: e.target.value })
                              })}
                            />
                        </Inputs.StyledInputContainer>
                        <Inputs.StyledInputContainer>
                            <Inputs.Label>Hold Time</Inputs.Label>
                            <Inputs.Input
                              placeholder="Hold time (seconds)"
                              size="small"
                              type="number"
                              min={1}
                              {...register('holdSeconds', {
                                value: holdSeconds,
                                onChange: (e) => setExerciseVariable({ holdSeconds: e.target.value })
                              })}
                            />
                        </Inputs.StyledInputContainer>
                    </S.InputGroup>
                                    )}
                    {exerciseType === ExerciseTypes.ExerciseType.SETS_AND_BREATHS && (
                    <S.InputGroup>
                        <Inputs.StyledInputContainer>
                            <Inputs.Label>Sets</Inputs.Label>
                            <Inputs.Input
                              placeholder="Sets"
                              size="small"
                              type="number"
                              min={1}
                              {...register('breathSets', {
                                value: sets,
                                onChange: (e) => setExerciseVariable({ sets: e.target.value })
                              })}
                            />
                        </Inputs.StyledInputContainer>
                        <Inputs.StyledInputContainer>
                            <Inputs.Label>Breath Cycles</Inputs.Label>
                            <Inputs.Input
                              placeholder="Breath Cycles"
                              size="small"
                              type="number"
                              min={1}
                              {...register('breathCycles', {
                                value: amount,
                                onChange: (e) => setExerciseVariable({ amount: e.target.value })
                              })}
                            />
                        </Inputs.StyledInputContainer>
                        <Inputs.StyledInputContainer>
                            <Inputs.Label>Inhale Resistance</Inputs.Label>
                            <Inputs.Input
                              placeholder="Inhale Resistance"
                              size="small"
                              type="number"
                              min={1}
                              max={6}
                              {...register('inhaleResistance', {
                                value: inhaleResistance,
                                onChange: (e) => setExerciseVariable({ rmtInhaleResistance: e.target.value })
                              })}
                            />
                            <Inputs.Label>(max 6)</Inputs.Label>
                        </Inputs.StyledInputContainer>
                        <Inputs.StyledInputContainer>
                            <Inputs.Label>Exhale Resistance</Inputs.Label>
                            <Inputs.Input
                              placeholder="Exhale Resistance"
                              size="small"
                              type="number"
                              min={1}
                              max={5}
                              {...register('exhaleResistance', {
                                value: exhaleResistance,
                                onChange: (e) => setExerciseVariable({ rmtExhaleResistance: e.target.value })
                              })}
                            />
                            <Inputs.Label>(max 5)</Inputs.Label>
                        </Inputs.StyledInputContainer>
                    </S.InputGroup>
                )}
                </S.InputContainer>
                {!!errors && (
                <S.ErrorContainer>
                    {Object.values(errors).filter((e => e?.message !== '')).map((e) => <S.ErrorMessage>{e?.message?.toString()}</S.ErrorMessage>)}
                </S.ErrorContainer>
            )}
            </S.InputContainer>
        </S.Section>
    );
};

export default EditExerciseFormat;
