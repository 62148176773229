import { Buttons } from '@apps/common-ui';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { PageHeader } from '../../components/common/commonStyled';
import * as S from './index.styles';
import { NaiveDate, monthDateYear } from '../../utils/dateUtils';
import { useSelector } from 'react-redux';
import { RootState } from '../../state/store';
import { LearningTypes } from '@apps/common-utilities';
import { useActionLoader } from '../../hooks/useActionLoader';
import { fetchModules } from '../../state/reducers/learning';
import { useNavigate } from 'react-router';

const ColumnName = ({ name }: {name: string}) => {
    return (
        <S.ColumnNameContainer>
            <S.ColumnName>{name}</S.ColumnName>
        </S.ColumnNameContainer>
    );
};

const columns = [
    {
        name: <ColumnName name="Module" />,
        grow: 3,
        selector: (row: LearningTypes.IModule) => row.internalTitle,
        cell: (row: LearningTypes.IModule) => (
            <S.ModuleDetails>
                <S.ModuleImage src={row.thumbnailUrl} />
                <S.ModuleName>
                    <h3>{row.internalTitle}</h3>
                    <span>Last Updated: {row.updatedAt && monthDateYear(new NaiveDate(row.updatedAt))}</span>
                </S.ModuleName>
            </S.ModuleDetails>
        )
    },
    {
        name: <ColumnName name="Assigned to" />,
        grow: 1,
        selector: (row: LearningTypes.IModule) => row.assignedToCount,
    },
    {
        name: <ColumnName name="Lessons" />,
        grow: 1,
        selector: (row: LearningTypes.IModule) => row.lessonCount,
    },
    {
        name: <ColumnName name="Quizzes" />,
        grow: 1,
        selector: (row: LearningTypes.IModule) => row.quizCount,
    },
    {
        name: '',
        grow: 1,
        right: true,
        cell: (row: LearningTypes.IModule) => (
            <Buttons.LinkButton
              buttonType="tertiary"
              to={`/modal/modules/manage/${row.id}`}
            >
                Manage
            </Buttons.LinkButton>
        )
    }
];

const LearningLibrary = () => {
    const { modules } = useSelector((state: RootState) => state.learning);
    const navigate = useNavigate();
    const { callAction: getModules, done: modulesFetched } = useActionLoader(fetchModules);

    useEffect(() => {
        if (!modulesFetched) {
            getModules();
        }
    }, [modulesFetched]);

    return (
        <S.ModuleListContainer>
            <PageHeader>
                <S.PageTitle>Learning Module Library</S.PageTitle>
                <Buttons.Button buttonType="tertiary" onClick={() => navigate('/modal/modules/manage')}>+ New Module</Buttons.Button>
            </PageHeader>
            <DataTable
              columns={columns}
              data={modules}
              highlightOnHover
              pagination
              striped
              paginationDefaultPage={1}
              paginationPerPage={60}
            />
        </S.ModuleListContainer>
    );
};

export default LearningLibrary;
